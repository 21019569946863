import React from "react"

export default (props) => (
  <section className="hero is-black">
    <div className="hero-body">
      <div className="container">
        <h1 className="title">
          {props.title}
        </h1>
        <h2 className="subtitle">
          <div dangerouslySetInnerHTML={{ __html: props.subtitle }} />
        </h2>
      </div>
    </div>
  </section>
)
