import React from "react"
import Navbar from "./navbar"
import Hero from "./hero"
class Layout extends React.Component {
  render () {
    const { title, subtitle, children } = this.props
    return (
      <div>
        <Navbar />
        <Hero title={title} subtitle={subtitle}/>
        <main className="has-background-white-ter">
          {children}
        </main>
        <footer className="footer">
          <div className="content has-text-centered">
            © {new Date().getFullYear()}, ing. Bart Joostink
          </div>
        </footer>
      </div>
    )
  }
}
export default Layout
