import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
export default (props) =>
    <Link to={props.slug}>
      <div className="card has-background-white-bis project-card">
        <div className="card-image">
          <Img
            className="image"
            fluid={props.fluid}
            alt="cover"
          />
        </div>
        <div className="card-content">
          <div className="content">
            <h5 className="title is-5 has-text-grey is-uppercase">
              {props.title}
            </h5>
            <p>{props.excerpt}</p>
          </div>
        </div>
      </div>
    </Link>
